import React, { Component } from 'react';

import "./Tax.css";
import {Helmet, HelmetProvider} from "react-helmet-async";

class Tax extends Component {
    image = "https://s.yimg.com/uu/api/res/1.2/yBvZDJbHxQUX5TTJbkfSHA--~B/Zmk9c3RyaW07aD0zMjY7cT04MDt3PTU4MjthcHBpZD15dGFjaHlvbg--/https://s.yimg.com/os/creatr-uploaded-images/2023-01/eb970ab0-90dd-11ed-be7a-c5a18b4010c2.cf.webp"

    renderMetadata () {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>Yahoo! Finance - Tax Fest!</title>
                <meta name="description" content="This is the stock description" />
                <meta property="og:url" content="http://etherfirma.com/tax"/>
                <meta property="og:title" content="Taxes 2023: News, Tips, Dates & Latest Info - Yahoo Finance"/>
                <meta property="og:description" content="Find the latest tax news, tips, dates and info that you need to file taxes in 2023."/>
                <meta property="og:site_name" content="Yahoo! FInance" />
                <meta property="og:image" content={this.image} />
            </Helmet>
        );
    }

    render() {
        return (
            <HelmetProvider>
                <div className={"TaxWrapper"}>
                    {this.renderMetadata ()}
                    {/*<meta http-equiv="Refresh" content="0; url='https://www.w3docs.com'" />*/}
                    Temporary
                </div>
            </HelmetProvider>
        );
    }
}

export default Tax;

// EOF