import React, { Component } from 'react';

import "./Homepage.css"

class Homepage extends Component {
    render() {
        return (
            <div className={"Homepage"}>
                <div className={"HomepageContent"}>
                    etherfirma
                </div>
            </div>
        );
    }
}

export default Homepage;

// EOF